<script lang="ts" setup>
import type { LogicWrapperCollection } from '@integration-layer/ampliance/schemas/logic-wrapper-collection-schema.localized'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: LogicWrapperCollection
}>()

const data = await useComponentResponse<LogicWrapperCollection>(props)

const components = computed(() =>
  connectComponents(
    // @ts-ignore
    data.value?.listOfLogicWrapper ?? [],
    props.locale,
    props.vse
  )
)
</script>

<template>
  <div>
    <AmplienceDynamicContent :components first-page-content />
  </div>
</template>
